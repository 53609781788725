* {
    box-sizing: border-box;
}

html {
    height: 100%;
    box-sizing: border-box;
    background: #F5F5F5;
}

body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    background: #F5F5F5;
}

#root {
    height: 100%;
    width: 100%;
    background: #F5F5F5;
}

/* Disable MS Edge password eye icon */
::-ms-reveal {
    display: none;
}

/* Disable number input arrow buttons */
/*Chrome, Safari, Edge, Opera*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}